import { SVGProps } from 'react';

export default function Logo(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='109' height='32' viewBox='0 0 109 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M52.4229 24.6491V18.8982L57.9677 10.2203H54.3605L50.8563 16.0124L47.414 10.2203H43.7037L49.2485 18.96V24.6491H52.4229ZM73.6668 24.6491V21.8252H65.834V18.7951H72.6361V15.9712H65.834V13.0442H73.5637V10.2203H62.6802V24.6491H73.6668ZM88.7277 24.6491V21.7633H81.5339V10.2203H78.3596V24.6491H88.7277ZM96.6448 24.6491L97.964 21.4129H104.065L105.385 24.6491H108.703L102.519 10.1172H99.5924L93.4086 24.6491H96.6448ZM103.004 18.6096H99.1698L101.087 13.9305L103.004 18.6096Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6036 0H18.9549L11.027 10.241L25.4415 10.2344L22.054 15.3577L11.027 32H3.96394L14.991 15.3514H0L11.6036 0Z'
        fill='#FFDB01'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.7476 17.6641L34.8828 17.6575L31.4953 22.7807L30.2701 24.6482H23.207L24.4323 22.7744L27.7476 17.6641Z'
        fill='#FFDB01'
      />
    </svg>
  );
}
