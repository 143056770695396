import API from '@api/api-base';
import { BaseResponse } from '@api/model/base-response';
import Endpoints from '@constants/endpoints';

interface LogInRequest {
  email: string;
  password: string;
}

export interface LogInResponse {
  id: string;
  name: string;
  email: string;
  accessToken: string;
  accessValidUntil: number;
  refreshToken: string;
  refreshValidUntil: number;
  type: string;
}

export const LogIn = async (info: LogInRequest): Promise<BaseResponse<LogInResponse>> => {
  const result = await API.post<LogInRequest, LogInResponse>(Endpoints.LOGIN, info);
  return result;
};

export const LogOut = async (userAuthDataLogout: LogInResponse): Promise<BaseResponse<any>> => {
  const result = await API.post<any, any>(Endpoints.LOGOUT, { userAuthDataLogout });
  return result;
};

interface RegisterRequest {
  name: string;
  email: string;
  password: string;
}

export const Register = async (info: RegisterRequest): Promise<BaseResponse<LogInResponse>> => {
  const result = await API.post<RegisterRequest, LogInResponse>(Endpoints.REGISTER, info);
  return result;
};

interface ForgotPasswordRequest {
  email: string;
}

export const ForgotPassword = async (info: ForgotPasswordRequest): Promise<BaseResponse<any>> => {
  const result = await API.post<ForgotPasswordRequest, any>(Endpoints.FORGOT_PASSWORD, info);
  return result;
};

interface ResetPasswordRequest {
  email: string;
  resetCode: number;
  newPassword: string;
}

export const ResetPassword = async (info: ResetPasswordRequest): Promise<BaseResponse<any>> => {
  const result = await API.post<ResetPasswordRequest, any>(Endpoints.RESET_PASSWORD, info);
  return result;
};

interface LanguageChangeRequest {
  language: string;
}

export const LanguageChange = async (info: LanguageChangeRequest): Promise<BaseResponse<any>> => {
  const result = await API.post<LanguageChangeRequest, any>(Endpoints.LANGUGAGE_CHANGE, info);
  return result;
};

interface FacebookLogInRequest {
  accessToken: string;
}

export const FacebookLogIn = async (info: FacebookLogInRequest): Promise<BaseResponse<LogInResponse>> => {
  const result = await API.post<FacebookLogInRequest, LogInResponse>(Endpoints.FACEBOOK_LOGIN, info);
  return result;
};
