import * as MixpanelGeneralActions from '@analytics/mixpanel/actions/General';
import Mixpanel from '@analytics/mixpanel/Mixpanel';
import * as AuthAPI from '@api/Auth';
import { getUserData, invalidateUserData } from '@common/utils';
import { LanguageSwitcher } from '@components/Language/LanguageSwitcher';
import { TLanguageItemProps } from '@components/Language/LanguageSwitcher.types';
import Routes from '@constants/routes';
import { I18N_COMMON_CONTEXT, LOGIN, LOGOUT, WELCOME } from '@features/header/Header.constants';
import {
  HeaderItems,
  HeaderLogo,
  HeaderWrapper,
  LoginLink,
  LogoutContainer,
  UserIcon,
  WelcomeText,
  WelcomeTextWrapper,
  WelcomeUserName,
  WelcomeUserNameText,
  WelcomeWrapper,
} from '@features/header/Header.styles';
import { THeaderProps } from '@features/header/Header.types';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';

const Header = ({ userName, setUser }: THeaderProps): JSX.Element => {
  const router = useRouter();
  const { t } = useTranslation(I18N_COMMON_CONTEXT);
  const [logoutContainerOpened, setLogoutContainerOpened] = useState(false);

  const returnHome = async () => {
    MixpanelGeneralActions.onYelaLogoClicked({ router, position: 'Header' });
    await router.push(Routes.HOME);
  };

  const proccesLogOut = () => {
    MixpanelGeneralActions.onLogoutButtonClicked({ router });
    const userAuthData = getUserData(document.cookie);
    if (userAuthData) AuthAPI.LogOut(userAuthData);
    setLogoutContainerOpened(false);
    if (setUser) setUser({} as AuthAPI.LogInResponse);
    invalidateUserData();
    Mixpanel.reset();
    router.push(Routes.HOME);
  };

  const languages: TLanguageItemProps[] = [
    {
      rtl: false,
      display: 'English',
      language: 'en',
    },
    {
      rtl: true,
      display: 'العربية',
      language: 'ar',
    },
  ];

  return (
    <HeaderWrapper>
      <HeaderLogo onClick={returnHome} />
      <HeaderItems>
        {userName ? (
          <WelcomeWrapper>
            <WelcomeTextWrapper>
              <WelcomeText>{t(WELCOME)}</WelcomeText>
              <ClickAwayListener
                onClickAway={() => {
                  setLogoutContainerOpened(false);
                }}
              >
                <WelcomeUserName
                  onClick={() => {
                    setLogoutContainerOpened(!logoutContainerOpened);
                  }}
                >
                  <WelcomeUserNameText
                    onClick={() => {
                      MixpanelGeneralActions.onUserProfileClicked({ router });
                    }}
                  >
                    {userName}
                  </WelcomeUserNameText>
                  {logoutContainerOpened && <LogoutContainer onClick={proccesLogOut}>{t(LOGOUT)}</LogoutContainer>}
                </WelcomeUserName>
              </ClickAwayListener>
            </WelcomeTextWrapper>
            <UserIcon />
          </WelcomeWrapper>
        ) : (
          <>
            {router.pathname != Routes.LOGIN && (
              <LoginLink
                onClick={() => {
                  router.push(Routes.LOGIN);
                }}
              >
                {t(LOGIN)}
              </LoginLink>
            )}
          </>
        )}
        <LanguageSwitcher languages={languages} />
      </HeaderItems>
    </HeaderWrapper>
  );
};

export default Header;
