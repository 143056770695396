import { SVGProps } from 'react';

export default function User(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 4.33301C5 5.98701 6.346 7.33301 8 7.33301C9.654 7.33301 11 5.98701 11 4.33301C11 2.67901 9.654 1.33301 8 1.33301C6.346 1.33301 5 2.67901 5 4.33301ZM13.3333 13.9997H14V13.333C14 10.7603 11.906 8.66634 9.33333 8.66634H6.66667C4.09333 8.66634 2 10.7603 2 13.333V13.9997H13.3333Z'
        fill='white'
        fillOpacity='0.92'
      />
    </svg>
  );
}
