import LangArrow from '@assets/language_arrow';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import tw from 'twin.macro';

export const LanguageSwitcherContainer = styled.span`
  ${tw`relative`}
  ${tw`text-light-high cursor-pointer`}
`;

export const LanguageArrow = styled(LangArrow)`
  ${tw`inline-block ms-1`}
`;

export const LanguagesList = styled.div`
  direction: ltr;
  width: 160px;
  background-color: #202025;
  ${tw`absolute mt-2 end-0`}
`;

export const LanguageEntryStyle = (rtl: boolean): SerializedStyles => css`
  ${tw`p-2 flex`};
  ${rtl && tw`justify-between`};
  &:hover {
    background-color: #303036;
  }
`;

export const LanguageCode = styled.span`
  ${tw`me-2`}
`;
