import YelaLogo from '@assets/logo';
import User from '@assets/user';
import styled from '@emotion/styled';
import tw, { css, theme } from 'twin.macro';

export const HeaderWrapper = styled.div`
  ${tw`sm:h-9 md:h-9 lg:h-12 xl:h-12`}
  ${tw`sm:px-2 md:px-3 lg:px-8 xl:px-8`}
  ${tw`flex justify-between items-center z-40`}
`;

export const HeaderLogo = styled(YelaLogo)`
  ${tw`cursor-pointer`}
`;

export const HeaderItems = styled.div`
  ${tw`flex flex-row space-s-3 items-center`}
`;

export const WelcomeWrapper = styled.span`
  ${tw` text-light-high flex items-center relative`}
`;

export const WelcomeTextWrapper = styled.span`
  ${tw` whitespace-nowrap overflow-hidden overflow-ellipsis inline-block`};
  ${css`
    @media (min-width: ${theme`screens.sm.min`}) and (max-width: 400px) {
      max-width: 15vw;
    }
    @media (min-width: 401px) and (max-width: ${theme`screens.md.max`}) {
      max-width: 25vw;
    }
    @media (min-width: ${theme`screens.lg.min`}) {
      max-width: 45vw;
    }
  `}
`;

export const WelcomeText = styled.span`
  ${tw`sm:hidden`}
  ${tw`md:hidden`}
`;

export const WelcomeUserName = styled.span`
  ${tw`font-semibold cursor-pointer select-none`}
`;

export const WelcomeUserNameText = styled.span`
  ${tw``}
`;

export const LogoutContainer = styled.div`
  background-color: #202025;
  width: 160px;
  ${tw`text-light-high absolute mt-2 p-3 end-0 cursor-pointer text-brand-error`}
  &:hover {
    background-color: #303036;
  }
`;

export const UserIcon = styled(User)`
  ${tw`inline-block ms-1`}
`;

export const LoginLink = styled.span`
  ${tw`text-light-high font-semibold cursor-pointer select-none`}
`;
