import { SeoLink } from '@common/SeoLink';
import {
  LanguageArrow,
  LanguageCode,
  LanguageEntryStyle,
  LanguagesList,
  LanguageSwitcherContainer,
} from '@components/Language/LanguageSwitcher.styles';
import { TLanguageItemProps } from '@components/Language/LanguageSwitcher.types';
import { TLanguage, useYela } from '@hooks/useYela';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';

const LanguageEntry = ({ rtl, display, language }: TLanguageItemProps): JSX.Element => {
  const { setLanguage } = useYela();

  return (
    <div
      css={LanguageEntryStyle(rtl)}
      onClick={async () => {
        await setLanguage(language, true);
      }}
    >
      <LanguageCode>{language.toUpperCase()}</LanguageCode>
      <span>{display}</span>
    </div>
  );
};

export const LanguageSwitcher = ({ languages }: { languages: TLanguageItemProps[] }): JSX.Element => {
  const [opened, setOpened] = useState(false);
  const { language, setLanguage } = useYela();
  const router = useRouter();

  useEffect(() => setLanguage(router.locale as TLanguage, false), [router]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpened(false);
      }}
    >
      <LanguageSwitcherContainer onClick={() => setOpened(!opened)}>
        <SeoLink href={'/'} />
        {language.toUpperCase()}
        <LanguageArrow />
        {opened && (
          <LanguagesList>
            {languages.map(language => (
              <LanguageEntry key={language.language} {...language} />
            ))}
          </LanguagesList>
        )}
      </LanguageSwitcherContainer>
    </ClickAwayListener>
  );
};
