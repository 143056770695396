import * as MixpanelGeneralActions from '@analytics/mixpanel/actions/General';
import * as AuthAPI from '@api/Auth';
import { getUserData } from '@common/utils';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useContext, useState } from 'react';

export type TLanguage = 'en' | 'ar';

type THasChild = {
  children: ReactNode;
};

type TYelaContext = {
  language: TLanguage;
  setLanguage: (lang: TLanguage, forceCookie: boolean) => void;
};

const defaultYelaContext: TYelaContext = {
  language: 'en',
  setLanguage: lang => {
    console.log('DEFAULT');
    return;
  },
};

const setLangCookie = (lang: TLanguage) => {
  const date = new Date();
  const expireMs = 100 * 365 * 24 * 60 * 60 * 1000; // 100 days
  date.setTime(date.getTime() + expireMs);
  document.cookie = `NEXT_LOCALE=${lang};expires=${date.toUTCString()};path=/`;
};

const UseYela = createContext<TYelaContext>(defaultYelaContext);

export function useYela(): TYelaContext {
  return useContext(UseYela);
}

export function YelaContextProvider({ children }: THasChild): JSX.Element {
  const router = useRouter();
  const [language, setLanguage] = useState<TLanguage>(router.locale as TLanguage);

  const value: TYelaContext = {
    language: language,
    setLanguage: (lang, forceCookie = true) => {
      if (language == lang) return;
      if (forceCookie) setLangCookie(lang);
      const user = getUserData(document.cookie);
      if (user) {
        AuthAPI.LanguageChange({
          language: lang,
        });
      }
      MixpanelGeneralActions.onNavigationLanguageSwitch({ router, newLanguage: lang });
      const html = document.getElementsByTagName('html')[0];
      lang == 'ar' ? html.setAttribute('dir', 'rtl') : html.setAttribute('dir', 'ltr');
      setLanguage(lang);
      router.push({ pathname: router.pathname, query: router.query }, ``, {
        locale: lang,
      });
    },
  };
  return (
    <>
      <UseYela.Provider value={value}>{children}</UseYela.Provider>
    </>
  );
}
