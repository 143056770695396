import { SVGProps } from 'react';

export default function LanguageArrow(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.00417 7.9795C6.83053 7.97971 6.65883 7.94289 6.50055 7.87148C6.34227 7.80008 6.20104 7.69573 6.08628 7.56541L0.877843 1.64507C0.713222 1.44888 0.631892 1.19607 0.651242 0.940704C0.670593 0.685333 0.789088 0.447668 0.98139 0.278529C1.17369 0.109389 1.42454 0.0221987 1.68029 0.0356026C1.93604 0.0490064 2.1764 0.16194 2.34997 0.350255L6.90436 5.52737C6.91682 5.54158 6.93217 5.55297 6.94938 5.56077C6.96659 5.56857 6.98527 5.57261 7.00417 5.57261C7.02307 5.57261 7.04175 5.56857 7.05896 5.56077C7.07617 5.55297 7.09152 5.54158 7.10397 5.52737L11.6584 0.350255C11.7424 0.250145 11.8455 0.167837 11.9618 0.108169C12.0781 0.0485011 12.2051 0.0126771 12.3354 0.00280293C12.4657 -0.00707123 12.5967 0.0092035 12.7206 0.0506706C12.8446 0.0921377 12.959 0.15796 13.0571 0.24427C13.1552 0.330579 13.2351 0.435634 13.292 0.553259C13.349 0.670884 13.3818 0.798706 13.3887 0.929212C13.3955 1.05972 13.3762 1.19027 13.3319 1.31321C13.2875 1.43614 13.2191 1.54897 13.1305 1.64507L7.92365 7.56329C7.80869 7.69393 7.66724 7.79861 7.50871 7.87037C7.35018 7.94213 7.17819 7.97933 7.00417 7.9795Z'
        fill='white'
        fillOpacity='0.92'
      />
    </svg>
  );
}
